import { useCallback, useEffect, useState } from 'react';

import { SignupCountry } from './TermsOfUse.decl';

import { TermsOfUseModal } from '@components/TermsOfUse/TermsOfUseModal';
import { logger } from '@config/logger.config';
import { ASSETS_PAGE_ROUTE } from '@constants/regex.constants';
import { Loading } from '@dashboard/library';
import { hasUserSignedLatestContracts, setSignerCustomData } from '@helpers/ironclad.helpers';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { usePathnameMatch } from '@hooks/usePathnameMatch';
import { useQueryLocalisedContracts } from '@hooks/useQueryLocalisedContracts';

export function TermsOfUseConditionalAccess({
  children,
  signupCountry,
}: {
  children: JSX.Element;
  signupCountry: SignupCountry;
}) {
  const skip = usePathnameMatch([ASSETS_PAGE_ROUTE]);
  const { showOnboardingTrial, enableStarterPlan } = useFeatures();

  const {
    data: { contractIds },
  } = useQueryLocalisedContracts(signupCountry, skip);
  const {
    authState: { isConnectedAs },
  } = useAuthenticationState();

  const {
    currentCompany: { id: companyId, name: companyName },
    currentUser: { email, firstName, lastName, ID: userId },
  } = useGlobalData();
  const [displayModal, setDisplayModal] = useState<boolean>();

  const shouldDisplayLatestTerms = useCallback(async () => {
    if (!contractIds || showOnboardingTrial || enableStarterPlan) {
      return false;
    }
    try {
      await setSignerCustomData({ companyId, companyName, email, firstName, lastName, userId });
      const hasSignedLatestTerms = await hasUserSignedLatestContracts(contractIds, companyId);
      return !isConnectedAs && !hasSignedLatestTerms;
    } catch (error) {
      const originalError = (error as Error).message;
      logger.error(`Unhandled exception while accepting T&C, ${originalError}`);
      return false;
    }
  }, [
    companyId,
    companyName,
    contractIds,
    email,
    firstName,
    isConnectedAs,
    lastName,
    userId,
    showOnboardingTrial,
    enableStarterPlan,
  ]);

  useEffect(() => {
    const setModal = async () => {
      const modalEnabled = await shouldDisplayLatestTerms();
      setDisplayModal(modalEnabled);
    };
    setModal();
  }, [contractIds, shouldDisplayLatestTerms]);

  // We don't want to display the page before promise resolves
  if (displayModal === undefined) {
    return <Loading data-test='terms-of-use-conditional-loading' />;
  }

  return displayModal ? (
    <TermsOfUseModal companyId={companyId} signupCountry={signupCountry} />
  ) : (
    children
  );
}
