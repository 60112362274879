import { NUMBERS_ROUTE, ONBOARDING_ROUTE, LANDING_PAGE_ROUTE } from '@constants/routes.constants';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { usePermissions } from '@hooks/usePermissions';
import { useOnboarding } from '@pages/onboarding/hooks/useOnboarding';
import { Navigate, useLocation } from 'react-router';

type LocationState = {
  fromPasswordScreen?: boolean;
};

/* istanbul ignore next */
export function Home() {
  const location = useLocation();
  const state = location.state as LocationState;
  const { showOnboardingTrial, enableStarterPlan } = useFeatures();
  const { isOnboardingRedirectActive } = useOnboarding();
  const { getAccessibleRoute } = usePermissions();
  const defaultRoute = getAccessibleRoute() || NUMBERS_ROUTE;
  let redirectTo;
  if ((showOnboardingTrial || enableStarterPlan) && state?.fromPasswordScreen) {
    redirectTo = LANDING_PAGE_ROUTE;
  } else if (isOnboardingRedirectActive) {
    redirectTo = ONBOARDING_ROUTE;
  } else {
    redirectTo = defaultRoute;
  }
  return <Navigate to={redirectTo} />;
}
